.dateNumber {
  background-color: #d3d3d3;
  padding-top: 0.42rem;
  padding-bottom: 0.4rem;
  border-radius: 8px;
  width: 90%;
  font-weight: bold;
  margin-left: -8;
}

.container {
  margin-top: 1rem;
}

.container.containerExceededMaxDistanceToExtremeCost{
  background-color: var(--red-6-color);
}

.number {
  background-color: #ffa500;
  color: #ffffff;
  font-weight: bold;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 6px 0px 0px 6px;
}

.solicitation {
  padding: 0.5rem 0rem;
  font-size: 12px;
}

.solicitation:not(:last-child){
  border-bottom: 1px solid var(--light-gray-color);
}

.headerItem {
  font-size: 12px;
  /* padding-top: 0.5rem; */
  padding-left: 0.5rem;
}

.item {
  font-size: 12px;
  padding-left: 0.5rem;
}

.iconContainer {
  display: flex;
  padding-right: 1rem;
  text-align: right;
  cursor: pointer;
}

.isExtremeCost, .exceededMaxDistanceToExtremeCost{
  display: inline-block;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  padding: 0 0 var(--half-space) var(--half-space);
  border-bottom: 1px solid var(--light-gray-color);
}

.exceededMaxDistanceToExtremeCost{
  color: var(--red-color);
  padding-top: var(--half-space);
}
