.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 24px;
}

.arrowIcon {
  margin-right: 0.75rem;
  margin-bottom: -0.25rem;
  cursor: pointer;
}

.instruction {
  color: #a8a8a8;
  font-size: 12px;
}

.container {
  padding: 1rem;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  height: 510px;
}

.tableTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.tableTitle {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
}

.icon {
  font-size: 16px;
  cursor: pointer;
}

.tableCounter {
  font-size: 14px;
  color: #909090;
  margin-left: 0.5rem;
}

.modalContainer {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalContent {
  text-align: center;
}

.modalInstruction {
  text-align: center;
  font-size: 1.2rem;
  color: #909090;
  margin-bottom: 2rem;
}

.modalActions {
  text-align: right;
}
.saveButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.fileUploader {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.fileUploader + label {
  font-size: 1rem;
  padding: 1rem;
  font-weight: 700;
  border-radius: 8px;
  color: rgb(68, 68, 68);
  background-color: #fcba49;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fileUploader:focus + label,
.fileUploader + label:hover {
  background-color: #fcc465;
}

.uploadIcon {
  margin-bottom: 0rem;
  margin-right: 1rem;
  font-size: 1.35rem;
}

.inputContainer {
  width: 25%;
  margin-bottom: 1rem;
}

.pen {
  color: #c2c2c2;
  font-size: 12px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.containerItem {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.value {
  display: flex;
  align-items: center;
}

.percentage {
  font-size: 14px;
}

.parameter {
  font-size: 22px;
}

.avatar {
  margin-right: 1rem;
}

.dollar {
  color: black;
  margin-top: 0.5rem;
}

.description {
  display: flex;
  align-items: center;
}

.infoPrimary {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
}

.infoSecondary {
  font-weight: 400;
  font-size: 14px;
}
