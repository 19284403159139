.container {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  margin-right: 0.5rem;
}

.dollar {
  color: black;
  margin-top: 0.5rem;
}

.description {
  display: flex;
}

.infoPrimary {
  margin-bottom: 0;
  font-weight: bold;
}

.infoSecondary {
  font-weight: 400;
  font-size: 12px;
}

.value {
  display: flex;
  align-items: center;
}

.percentage {
  font-size: 14px;
}

.pen {
  color: #c2c2c2;
  font-size: 12px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.cost {
  font-size: 18px;
}
