.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    padding-left: 0.5rem;
    line-height: 1.2;
    margin: var(--half-space) 0 0;
}

.title{
    font-size: inherit;
    margin: 0 0 var(--small-space);
}

.list{
    margin-bottom: 0;
    padding-left: var(--default-space);
}
