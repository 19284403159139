.field{
  display: flex;
  flex-direction: column;
  margin-top: var(--default-space);
}

.label {
  font-weight: bold;
}

.textarea {
  resize: none;
  margin-top: 0.5rem;
}
