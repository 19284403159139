.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 32px;
}

.pageHeadingCounter {
  color: #909090;
  font-size: 20px;
  margin-left: 1rem;
}

.instruction {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2rem;
}

.filterContainer {
  margin-bottom: 1rem;
  border-radius: 8px;
  font-size: 14px;
}

.filterTitle {
  display: block;
  font-size: 14px;
  margin: 0rem 0 1rem 0;
  color: #909090;
  font-weight: 400;
}

.filterFields {
  display: flex;
}

.filterFields div + div {
  margin-left: 1rem;
}

.field {
  margin-top: 0.5rem;
}

.fieldSelect {
  margin-top: 0.5rem;
  display: block;
  width: 250px;
}

.label {
  font-weight: bold;
}

.approveAll {
  border: 1px solid #5a8ba8;
  border-radius: 6px;
  color: #fff;
  background-color: #5a8ba8;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 160px;
  cursor: pointer;
}

.chargeAll {
  border: 1px solid #87d068;
  border-radius: 6px;
  color: #fff;
  background-color: #87d068;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  width: 160px;
  cursor: pointer;
}

.actionContainer {
  display: flex;
  align-items: center;
}

.exportContainer {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.exportButton {
  width: 100px;
  color: #4e99c5;
  background-color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.link {
  color: blue;
  font-size: 12px;
  cursor: pointer;
}

.icon {
  color: #909090;
  height: 1rem;
  margin-right: 0.45rem;
  width: 1rem;
}
