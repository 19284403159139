.logoContainer {
  height: 32px;
  width: auto;
}

.logo {
  max-height: 100%;
  margin-right: 1rem;
}

.headerTitle {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}
