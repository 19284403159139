.instruction {
  color: #909090;
}

.costTitle {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 18px;
}

.baseCostContainer {
  margin-bottom: 1rem;
}

.costsContainer {
  background-color: #ebebeb;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
}

.extraCostsContainer {
  margin-top: 0.75rem;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.middleColumn {
  flex-grow: 1;
  border-bottom: 1px dashed black;
  margin: 0 4px 4px 4px;
}

.sumContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subtitle {
  margin-bottom: 0.5rem;
}

.total {
  font-weight: 700;
  padding-right: 1rem;
  margin: 0;
}

.totalValue {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.sum {
  margin-left: 1rem;
  font-weight: bold;
}
.label {
  font-weight: bold;
}

.labelInput {
  font-size: 12px;
}

.value {
  margin-right: 0.5rem;
}

.editIcon {
  font-size: 12px;
}

.inputContainer {
  margin-top: 0.25rem;
  width: 100%;
}

.textAreaContainer {
  margin-top: 0.25rem;
}
