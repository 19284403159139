.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 32px;
  margin-bottom: 0;
}

.pageImporting {
  font-size: 20px;
  margin-bottom: 1rem;
  color: gray;
}

.pageHeadingCounter {
  color: #909090;
  font-size: 20px;
  margin-left: 1rem;
}

.primaryButton {
  --height: 48px;

  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.6rem 1.25rem;
  height: var(--height);
  min-height: var(--height);
  max-height: var(--height);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: var(--half-space);
}

.buttonIcon {
  padding-top: 0.5rem;
  padding-left: 0.35rem;
}

.buttonsContainer {
  width: auto;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buttonsList {
  list-style: none;
  padding: 0;
}

.buttonElement {
  padding: 1rem;
  cursor: pointer;
}

.importIcon {
  font-size: 1rem;
  margin-bottom: -0.2rem;
  margin-right: 0.5rem;
}

.secondaryButton {
  background-color: #fff;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #5a8ba8;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.75rem 2rem;
}

.passengersWithoutLocality {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 12px;
}

.exportContainer {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.exportButton {
  margin-top: 1rem;
  width: 100px;
  color: #4e99c5;
  background-color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.danger {
  color: red;
  margin-right: 0.5rem;
}

.permissionIcon {
  color: #909090;
  height: 1rem;
  margin-right: 0.45rem;
  margin-top: 0.45rem;
  width: 1rem;
}

.actionButton{
  border: 0;
  padding: 0 var(--small-space);
  height: 18px;
}

.actionButton:focus-visible{
  outline: var(--little-space) solid;
}

.actionIcon{
  --size: 1rem;

  color: var(--gray-color);
  width: var(--size);
  height: var(--size);
}

.exclamationIcon {
  color: red;
  margin-left: 0.25rem;
  margin-bottom: -0.1rem;
}

.rowInstruction {
  font-size: 1rem;
  color: #909090;
}

.checkboxContainer {
  width: 85%;
}

.inputContainer {
  width: 25%;
  margin-bottom: 0.5rem;
}

.actions{
  display: flex;
  align-items: center;
  column-gap: var(--default-space);
}
