:root{
  --red-color: #db0000;
  --red-6-color: rgba(219, 0, 0, 0.06);
  --light-gray-color: #d3d3d3;
  --gray-color: #909090;
  --dark-gray-color: #595959;
  --white-color: #fff;
  --primary-color: #ffa500;
  --light-blue-color: #4e99c5;
  --black-72-color: rgba(0, 0, 0, 0.72);
  --little-space: 2px;
  --small-space: 4px;
  --half-space: 8px;
  --default-space: 16px;
  --double-space: 32px;
  --default-outline: var(--little-space) solid var(--primary-color);
  --transition-time: 200ms;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.field{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
}

.hint{
  font-size: 10px;
  color: var(--gray-color);
  display: block;
}

.form{
  display: flex;
  flex-direction: column;
  row-gap: var(--default-space);
}

.fields-container{
  display: flex;
  column-gap: var(--default-space);
}

.ant-dropdown.ant-dropdown-placement-bottomLeft{
  width: auto !important;
  min-width: auto !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft::before{
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--half-space);
  width: fit-content;
  padding: var(--little-space) var(--default-space);
  font-size: 14px;
  color: var(--light-blue-color);
  background-color: transparent;
  border: var(--little-space) solid var(--light-blue-color);
  border-radius: var(--little-space);
  transition: opacity var(--transition-time);
  cursor: pointer;
}

.export-button:focus-visible{
  outline: var(--default-outline);
}

.export-button:hover, .export-button:focus{
  opacity: .8;
}

.export-button:active{
  opacity: .6;
}

.export-button:disabled{
  opacity: .4;
  pointer-events: none;
}

.action-button{
  height: 20px;
  padding: 0 var(--small-space);
  border: 0;
}

.action-button:focus-visible{
  outline: var(--little-space) solid;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner{
  animation: spinner 1.5s linear infinite;
}

.backdrop{
  color: var(--white-color);
  background-color: var(--black-72-color);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
