.configContainer {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2rem;
  width: 370px;
}

.configUserInfo {
  margin-top: -0.25rem;
  padding: 0 1rem;
}

.configUserName {
  font-size: 24;
  font-weight: bold;
  margin-bottom: 0;
}

.configUserRole {
  color: #909090;
  font-size: 20;
}

.configManageLabel {
  color: '#909090';
  font-size: 16;
  margin-top: 1rem;
}

.configList {
  padding: 0.5rem 0 0 0;
  list-style: none;
}

.configItem {
  margin-left: 0.5rem;
  cursor: pointer;
  color: #007cc2;
}

.configIcon {
  color: #909090;
}

.configList li {
  margin-bottom: 0.5rem;
}

.actionContainer {
  margin-top: 1rem;
  text-align: center;
}

.logoutIcon {
  margin-right: 0.5rem;
}

.logoutButton {
  background-color: #fff;
  border: none;
  color: red;
  cursor: pointer;
}
