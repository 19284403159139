.actions{
  display: flex;
  column-gap: var(--default-space);
  justify-content: flex-end;
}

.infoFields{
  display: flex;
  column-gap: var(--default-space);
}
