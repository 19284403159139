.listContainer {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 1rem;
  width: auto;
  max-height: 250px;
  overflow-y: auto;
}

.listIcon {
  color: #909090;
  height: 1rem;
  margin-right: 0.45rem;
  width: 1rem;
}

.listTitle {
  color: #909090;
  margin-bottom: 1rem;
}

.slideButton {
  margin-left: 0.75rem;
  margin-top: 0.25rem;
}
