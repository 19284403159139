.title {
  font-weight: 700;
  margin: 0;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-color);
  margin: 0;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  margin: 0;
}

.registration {
  font-size: 12px;
  font-weight: 400;
}

.container{
  display: flex;
  flex-direction: column;
  row-gap: var(--default-space);
}

.row{
  display: flex;
  column-gap: var(--default-space);
  align-items: center;
}
