.label {
  font-weight: bold;
}

.inputContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.timeOverlay {
  height: 150px;
  overflow-y: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ant-input-affix-wrapper {
  width: 50%;
}
