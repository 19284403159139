.passengerName {
  margin-bottom: 0;
}

.registration {
  color: #909090;
  margin-bottom: 2rem;
}

.instruction {
  margin-bottom: 1rem;
}
