.actions{
  display: flex;
  justify-content: flex-end;
  column-gap: var(--default-space);
}

.title{
  margin: 0 0 calc(var(--little-space) * -1);
  line-height: 1;
}

.field{
  padding-bottom: var(--default-space);
  border-bottom: 1px solid var(--light-gray-color);
}
