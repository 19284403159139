.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--default-space);
}

.pageHeading {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: var(--half-space);
}

.contentContainer {
  padding: var(--half-space) var(--default-space) var(--default-space);
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
