.question {
  font-size: 20px;
  margin-bottom: 0;
}

.instruction {
  color: #909090;
}

.number {
  margin: 1rem;
  font-size: 16px;
}

.typingInstruction {
  margin-bottom: 1rem;
}

.charge {
  font-weight: bold;
  background-color: #87d068;
  color: white;
  padding: 0.25rem;
  border-radius: 4px;
}

.typingContainer {
  display: flex;
  justify-content: center;
}

.typing {
  width: 40%;
}

.error {
  display: block;
  color: red;
  font-size: 12px;
}

.chargeButton {
  border: 1px solid #87d068;
  border-radius: 6px;
  color: #fff;
  background-color: #87d068;
  padding: 0.5rem 0;
  margin: 1rem;
  width: 160px;
  cursor: pointer;
}

.chargeButton:disabled {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  color: rgb(141, 141, 141);
  cursor: not-allowed;
}
