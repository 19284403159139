.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 24px;
}

.arrowIcon {
  margin-right: 0.75rem;
  margin-bottom: -0.25rem;
  cursor: pointer;
}

.CNPJ {
  margin-left: 1rem;
  font-size: 16px;
  color: #909090;
}

.buttonContainer {
  text-align: right;
  margin-top: 1.5rem;
}

.title {
  font-size: 18px;
  margin-bottom: 0;
}

.withoutCostCounter {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 12px;
}

.icon {
  font-size: 16px;
  cursor: pointer;
}

.tableCounter {
  font-size: 14px;
  color: #909090;
  margin-left: 0.5rem;
}

.danger {
  color: red;
  margin-right: 0.5rem;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.primaryButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.6rem 1.25rem;
}

.modalContainer {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalContent {
  text-align: center;
}

.modalInstruction {
  text-align: center;
  font-size: 1.2rem;
  color: #909090;
  margin-bottom: 2rem;
}

.modalActions {
  text-align: right;
}
.saveButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.fileUploader {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.fileUploader + label {
  font-size: 1rem;
  padding: 1rem;
  font-weight: 700;
  border-radius: 8px;
  color: rgb(68, 68, 68);
  background-color: #fcba49;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fileUploader:focus + label,
.fileUploader + label:hover {
  background-color: #fcc465;
}

.uploadIcon {
  margin-bottom: 0rem;
  margin-right: 1rem;
  font-size: 1.35rem;
}

.inputContainer {
  width: 25%;
  margin-bottom: 1rem;
}
