.modalContainer {
  height: 600px;
  position: relative;
  overflow-y: auto;
}

.loading {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 45%;
}

.loading > p {
  margin-top: 0.5rem;
}

.instruction {
  color: #909090;
  font-weight: normal;
  margin-bottom: var(--half-space);
}

.title {
  margin-bottom: 1rem;
}

.count {
  color: #909090;
  margin-left: 1rem;
  font-size: 1rem;
}

.tripContainer {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

.header {
  background-color: #fafafa;
  padding: 1rem;
}

.headerInfo {
  color: black;
}

.number {
  background-color: #ffa500;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
}

.number > span {
  position: relative;
  top: 15%;
  font-size: 16px;
  color: black;
}

.calculatingDistances{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: var(--black-72-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--small-space);
  z-index: 1;
  font-size: 32px;
  font-weight: 700;
  color: var(--white-color);
}
