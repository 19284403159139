.formContainer {
  margin-top: 3.5rem;
}

.headingTitle {
  letter-spacing: 0.1rem;
  margin-bottom: 0.75rem;
  font-weight: bold;
}

.subHeading {
  margin-bottom: 2rem;
  font-size: 16px;
  color: rgb(156, 156, 156);
  font-weight: 400;
}

.label {
  font-weight: bold;
}

.inputContainer {
  margin-top: 0.5rem;
}

.returnCheckbox {
  display: flex;
  margin: 0.5rem 0;
}

.hourReturnContainer {
  margin-top: 38px;
}

.soliciteButton {
  margin-left: 1rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: white;
  border: 1px solid #5a8ba8;
  background-color: #5a8ba8;
  border-radius: 8px;
}

.soliciteButton:disabled {
  color: rgb(107, 107, 107);
  border-color: #c2c2c2;
  background: #c2c2c2;
}

.soliciteButton:disabled:hover {
  cursor: default;
  color: rgb(107, 107, 107);
  border-color: #c2c2c2;
  background: #c2c2c2;
  cursor: not-allowed;
}

.soliciteButton:hover {
  cursor: pointer;
  background-color: #6397b6;
}

.cancelButton {
  background: white;
  font-size: 1rem;
  color: #909090;
  border: 1px solid white;
  background-color: white;
}

.cancelButton:hover {
  cursor: pointer;
}
