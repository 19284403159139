.question {
  font-size: 20px;
  margin-bottom: 1.5rem;
}

.containerForm {
  margin: 0 auto;
  width: 75%;
}

.inputContainer {
  text-align: left;
  margin-bottom: 1rem;
}

.label {
  font-weight: bold;
}

.input {
  margin-top: 0.5rem;
}

.error {
  color: red;
  font-weight: 400;
}

.buttonContainer {
  margin-top: 3rem;
  text-align: right;
}

.createButton {
  border: 1px solid #87d068;
  border-radius: 6px;
  color: #fff;
  background-color: #87d068;
  padding: 0.5rem 0;
  /* margin: 1rem; */
  width: 160px;
  cursor: pointer;
}

.createButton:disabled {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  color: rgb(141, 141, 141);
  cursor: not-allowed;
}
