.label {
  font-weight: bold;
}

.inputContainer {
  margin-top: 0.5rem;
}

.dateInput {
  width: 100%;
}
