.container {
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 300px;
  height: 250px;
  overflow-y: auto;
}

.title {
  color: #909090;
}

.item {
  padding: 0.25rem 0;
  cursor: pointer;
}
