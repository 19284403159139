.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 24px;
}

.arrowIcon {
  margin-right: 0.75rem;
  margin-bottom: -0.25rem;
}

.arrowIcon:hover {
  cursor: pointer;
}

.titleContainer {
  margin: 0 auto;
}

.question {
  text-align: center;
  margin-bottom: 2rem;
}
