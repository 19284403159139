.instruction {
  color: #909090;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.phone {
  margin-bottom: 1rem;
}

.title {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.mediumIcon {
  color: #909090;
  margin-right: 0.5rem;
}

.smallIcon {
  margin-right: 0.25rem;
}

.label {
  display: block;
  color: #909090;
  font-size: 0.8rem;
}

p {
  font-weight: 600;
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 195px;
}
