.icon {
  color: #909090;
  margin-bottom: -0.05rem;
  margin-right: 0.5rem;
}

.label {
  color: #909090;
}

.value {
  margin-top: 0.75rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.instruction {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #909090;
}

.locality {
  margin-top: 0.25rem;
  color: #909090;
}

.localityName {
  color: #595959;
  font-weight: bold;
  cursor: pointer;
}

.localityIcon {
  margin-left: 6px;
  font-size: 10px;
}

.map {
  border: 1px solid #cacaca;
  border-radius: 8px;
  height: 400px;
}
