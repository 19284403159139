.buttonContainer {
  text-align: center;
  margin: 1rem 0rem;
}

.newRequest {
  background: #5a8ba8;
  border-radius: 8px;
  border-color: #5a8ba8;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem;
}
