.container {
  min-height: 100vh;
}

.container footer{
  padding: var(--default-space);
}

.content {
  margin: 2rem;
  min-height: auto;
}

.footer {
  color: gray;
}

.footer:hover {
  color: blue;
}
