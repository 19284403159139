.countItens {
  color: #909090;
  text-align: left;
  margin-bottom: 1rem;
}

.plusIcon, .alertIcon {
  font-size: 1.5rem;
  margin-bottom: -0.3rem;
  margin-right: 0.5rem;
}

.plusIcon{
  color: #56a63a;
}

.alertIcon {
  color: var(--primary-color);
}

.infoIcon {
  font-size: 1.5rem;
  color: #909090;
  margin-bottom: -0.3rem;
  margin-right: 0.5rem;
}

.minusIcon {
  font-size: 1.5rem;
  color: #d70000;
  margin-bottom: -0.3rem;
  margin-right: 0.5rem;
}

.instructionContainer {
  text-align: left;
}

.instruction {
  font-size: 1rem;
  margin: 1rem 0;
}
