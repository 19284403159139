.label {
  font-weight: bold;
}

.inputContainer {
  margin-top: 0.5rem;
}

.suggestionsContainer {
  width: auto;
  position: relative;
}

.suggestionsLoader {
  position: absolute;
  height: 200px;
  width: 350px;
  z-index: 1;
  background-color: white;
  padding: 0.5rem 1rem 1rem 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.suggestionsContent {
  position: absolute;
  height: 200px;
  width: 350px;
  overflow-y: auto;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 0.5rem 1rem 0 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.icon {
  margin-right: 0.5rem;
}

.suggestion {
  padding: 0.5rem;
  padding-left: 1rem;
  border-bottom: 1px solid #ececec;
}

.suggestion:hover {
  cursor: pointer;
}
