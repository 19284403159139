.locality {
    color: var(--gray-color);
    font-size: 14px;
}

.localityName {
    color: var(--dark-gray-color);
    font-weight: 700;
    cursor: pointer;
}

.localityIcon {
    font-size: 12px;
}

.actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: var(--default-space);
    margin-top: calc(var(--double-space) * 2);
}

.fieldsContainer{
    display: flex;
    column-gap: var(--default-space);
}

.address{
    color: var(--dark-gray-color);
}

.addressTitle{
    color: var(--gray-color);
}
