.table ul{
  margin-bottom: 0 !important;
}

.instruction {
  margin-bottom: 1rem;
}

.icon {
  color: #909090;
  height: 1rem;
  margin: 0;
  width: 1rem;
}

.actionButton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  width: var(--default-space);
  height: var(--default-space);
  cursor: pointer;
}

.actionButton:focus-visible{
  outline: var(--default-outline);
}
