.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.editableContainer {
  width: 90%;
  margin-bottom: 1rem;
}

.editableSave {
  background-color: #5a8ba8;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  border: 1px solid #5a8ba8;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.editableCancel {
  background-color: rgb(209, 207, 207);
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 4px;
  color: rgb(73, 73, 73);
  cursor: pointer;
}
