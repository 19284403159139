.centerScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: url(assets/login-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.signIn {
  background-color: rgb(189, 184, 184);
}

.formContainer {
  height: 400px;
  width: 400px;
  background: #ffa500;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 0rem 2rem;
}

.logoContainer {
  margin: 1rem 0;
  padding: 1rem;
  height: 30%;
  width: 100%;
}

.logoImage {
  height: 100%;
  width: 100%;
}

.inputLogin {
  margin-bottom: 1rem;
}

.buttonLogin {
  width: 100%;
  color: white;
  background-color: #000;
  border-radius: 0.35rem;
  border: 1px solid #000;
  padding: 0.5rem;
  text-transform: uppercase;
}

.buttonLogin:hover {
  cursor: pointer;
  background-color: rgb(53, 52, 52);
}
