.container {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 300px;
}

.titleContainer {
  padding: 0.75rem;
  border-bottom: 1px solid #eeeeee;
}

.list {
  padding: 0.75rem;
  overflow-y: auto;
  height: 170px;
  border-bottom: 1px solid #eeeeee;
}

.listItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.listItem {
  padding: 0.5rem 0;
}

.number {
  background-color: #ffa500;
  padding: 0.25rem;
  border-radius: 6px;
  margin-right: 8px;
  color: #fff;
}

.footer {
  padding: 0.75rem;
}

.newTrip {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.newTrip:hover {
  color: #cacaca;
}
