.instruction {
  color: #909090;
  font-weight: 400;
}

.address {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.mediumIcon {
  color: #909090;
  margin-right: 0.5rem;
}

.smallIcon {
  margin-right: 0.25rem;
}

.label {
  color: #909090;
  font-size: 0.8rem;
}

.title {
  font-weight: bold;
}

.registration {
  font-weight: 400;
  font-size: 12px;
  margin-left: 0.5rem;
}

p {
  font-weight: 500;
}
