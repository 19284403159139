.container {
  padding: 1rem;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  height: 316px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 700;
}

.counter {
  font-size: 12px;
  color: #909090;
  margin-left: 0.5rem;
}

.listContainer {
  height: 220px;
  margin-top: 1rem;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.itemDescription {
  font-size: 13px;
  text-transform: uppercase;
}

.icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
