.inputContainer {
  text-align: left;
  margin-bottom: 1rem;
}

.label {
  font-weight: bold;
}

.input {
  margin-top: 0.5rem;
}

.error {
  color: red;
  font-weight: 400;
}
