.instruction {
  margin-bottom: 1rem;
}

.buttonContainer {
  margin-top: 1rem;
  text-align: right;
}

.exportContainer {
  margin: 1rem 0 .5rem;
  display: flex;
  justify-content: flex-end;
}

.exportButton {
  display: flex;
  align-items: center;
  width: 100px;
  color: #4e99c5;
  background-color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.primaryButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.6rem 1.25rem;
}

.primaryButton:hover {
  background-color: #639ec0;
  border: 1px solid #639ec0;
}

.icon {
  color: #909090;
  height: 1rem;
  margin-right: 0.45rem;
  margin-top: 0.45rem;
  width: 1rem;
  cursor: pointer;
}

.editButton, .actionButton{
  height: var(--default-space);
  padding: 0;
  margin-right: var(--half-space);
}

.editButton:focus-visible, .actionButton:focus-visible{
  outline: var(--default-outline);
}

.editIcon, .actionIcon{
  width: var(--default-space);
  height: var(--default-space);
  color: var(--gray-color);
}

.actions{
  display: flex;
  align-items: center;
  column-gap: var(--default-space);
  margin-top: var(--default-space);
}

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--small-space);
}

.fieldsContainer{
  display: flex;
  align-items: flex-end;
  column-gap: var(--default-space);
  margin-bottom: var(--default-space);
}

.timePicker{
  display: flex;
  flex-direction: column;
}

.timePicker > div{
  margin-top: 0;
  margin-bottom: 0;
}

.rangePicker{
  height: fit-content;
}

.passengerSelect{
  width: 324px;
}
