.solicitation {
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: inherit;
}

.date {
  margin-bottom: 0.5rem;
}

p {
  font-weight: 500;
  margin-bottom: 0;
}

.avatar {
  background-color: #e5e5e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 2.5rem;
  vertical-align: middle;
}

.avatarNumber {
  font-size: 12px;
  color: black;
}

.mediumIcon {
  color: #909090;
  margin-right: 0.25rem;
}

.label {
  color: #909090;
  font-size: 0.8rem;
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 195px;
}
