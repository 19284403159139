.cardContainer {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 1rem;
}

.cardContainer:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.lastSolicitations {
  margin-top: 3rem;
}

.headingTitle {
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.avatar {
  background-color: #ffa500;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
}

.avatarIcon {
  margin-top: 0.8rem;
  font-size: 20px;
  color: black;
}

.date {
  color: #909090;
  text-align: right;
  margin-bottom: 0;
}

.address {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.pinIcon {
  margin-right: 0.5rem;
}

.littleCircle {
  background: #909090;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-left: 5px;
  margin-bottom: 4px;
}
