.modalContainer {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalActions {
  text-align: right;
}

.modalContent {
  text-align: center;
}

.nextButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  width: 100px;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.prevButton {
  background-color: #fff;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #5a8ba8;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}

.fileUploader {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.fileUploader + label {
  font-size: 1rem;
  padding: 1rem;
  font-weight: 700;
  border-radius: 8px;
  color: rgb(68, 68, 68);
  background-color: #fcba49;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fileUploader:focus + label,
.fileUploader + label:hover {
  background-color: #fcc465;
}

.uploadIcon {
  margin-bottom: 0rem;
  margin-right: 1rem;
  font-size: 1.35rem;
}
