.cardContainer {
  width: 80%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}

.cardDescription {
  font-size: 13px;
  font-weight: 400;
  color: #9c9c9c;
}

.editIcon {
  color: #b1b1b1;
  margin-left: 0.5rem;
}

.editIconCNPJ {
  color: #b1b1b1;
  margin-left: 0.5rem;
  font-size: 12px;
}

.editButton {
  background-color: #ffa500;
  color: white;
  border-radius: 4px;
  border: 1px solid #ffa500;
  padding: 6px;
  margin-right: 8px;
  cursor: pointer;
}

.deleteButton {
  background-color: white;
  color: red;
  border-radius: 4px;
  border: 1px solid red;
  padding: 6px;
  cursor: pointer;
}
