.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 32px;
}

.icon {
  color: #909090;
  height: 1rem;
  margin-right: 0.45rem;
  margin-top: 0.45rem;
  width: 1rem;
}

.exportRecurrentSolicitationsButton{
  margin: 0 0 var(--default-space) auto;
}
