.locality {
  margin-top: 0.25rem;
  color: #909090;
}

.localityName {
  color: #595959;
  font-weight: bold;
  cursor: pointer;
  margin-left: 0.25rem;
}

.arrowIcon {
  margin-left: 6px;
  font-size: 10px;
}

.dangerIcon {
  margin-right: 4px;
  font-size: 12px;
  color: red;
}

/* POPOVER */
.container {
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 300px;
  height: 250px;
  overflow-y: auto;
}

.title {
  color: #909090;
}

.item {
  padding: 0.25rem 0;
  cursor: pointer;
}

.alertMessage{
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 400;
  line-height: 1.2;
}
