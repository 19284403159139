.pageHeadingContainer {
  border-bottom: 1px solid #909090;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeading {
  font-size: 32px;
}

.pageHeadingCounter {
  color: #909090;
  font-size: 20px;
  margin-left: 1rem;
}

.primaryButton {
  background-color: #5a8ba8;
  border: 1px solid #5a8ba8;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.6rem 1.25rem;
}

.listContainer {
  margin: 0 auto;
  width: 65%;
}

.errorContainer {
  /* text-align: center; */
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  color: red;
  font-size: 16px;
}

.errorIcon {
  color: red;
  margin-right: 0.5rem;
}
